import React from "react"

import MainLayout from "../components/MainLayout"
import { graphql } from "gatsby"
import max from "../data/images/maximilian_rom.jpg"
import styled from "styled-components"
import SEO from "../components/SEO"
import { colors } from "../styles/vars"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faFax,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import ModalImage from "react-modal-image"
import lageplan_large from "../data/images/lageplan_large.png"

const Container = styled.div`
  display: flex;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  @media (max-width: 769px) {
    flex-direction: column;
    margin: 0rem 1rem 4rem 1rem;
    text-align: center;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
  align-items: flex-end;
  padding: 2rem 3rem 2rem 4rem;
  background-color: ${colors.LIGHTGREY};
  @media (max-width: 769px) {
    order: 2;
    padding: 2rem 1rem 2rem 1rem;
    align-items: center;
  }
`

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 2rem 4rem 2rem 3rem;
  background-color: ${colors.LIGHTGREY};
  @media (max-width: 769px) {
    order: 1;
    padding: 2rem 1rem 2rem 1rem;
    justify-content: center;
  }
`

const Image = styled.img`
  max-height: 20rem;
`

const Table = styled.table`
  border: 1px solid ${colors.GREY};
  border-radius: 4px;
  background-color: white;
  & tr:nth-child(even) {
    background-color: ${colors.LIGHTGREY};
  }
  @media (min-width: 769px) {
    width: 100%;
  }
  @media (max-width: 769px) {
    margin: 0 1rem;
  }
`

const Center = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
`

const Welcome = styled.h1`
  padding: 0rem 4rem;
  @media (max-width: 900px) {
    padding: 2rem 4rem;
  }
`

const LinkStyling = { textDecoration: "none", color: "#404040" }

const Icon = styled.span`
  color: ${colors.BLUE};
`

const ModalImageContainer = styled.div`
  width: 18rem;
  @media (min-width: 770px) {
    padding-left: 2rem;
  }
`

const LeftColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
  @media (max-width: 769px) {
    text-align: center;
  }
`

export default ({ data, location: { pathname } }) => (
  <MainLayout>
    <SEO pathname={pathname} />
    <div style={{ textAlign: "center" }}>
      <Welcome className="hidden-mobile">
        Herzlich Willkommen in meiner Ordination für Urologie in Liesing
      </Welcome>
    </div>
    <Container style={{ marginBottom: "4rem" }}>
      <LeftColumn>
        <LeftColumnContent>
          <h2 style={{ margin: 0 }}>Dr. Maximilian Rom</h2>
          <p>
            Facharzt für Urologie, F.E.B.U. <br />
            Alle Kassen und privat
          </p>
          <p>
            Breitenfurter Straße 376/1/E/3
            <br />
            1230 Wien <br />
            (beim Einkaufszentrum Riverside) <br />
          </p>
        </LeftColumnContent>
      </LeftColumn>
      <RightColumn>
        <Image src={max} alt="Dr. Maximilian Rom" />
      </RightColumn>
    </Container>
    <Center>
      <h2>Kontakt</h2>
    </Center>
    <ContactContainer>
      <div>
        <div>
          <Icon>
            <FontAwesomeIcon icon={faPhone} />
          </Icon>{" "}
          <span>
            <a
              href={`tel:${data.site.siteMetadata.telephone}`}
              style={LinkStyling}
            >
              &nbsp;{data.site.siteMetadata.telephone}
            </a>
          </span>
        </div>
        <p>
          <Icon>
            <FontAwesomeIcon icon={faFax} />
          </Icon>{" "}
          <span>
            <a href={`tel:${data.site.siteMetadata.fax}`} style={LinkStyling}>
              &nbsp;{data.site.siteMetadata.fax}
            </a>
          </span>
        </p>
        <p>
          <Icon>
            <FontAwesomeIcon icon={faEnvelope} />
          </Icon>{" "}
          <span>
            <a
              href={`mailto:${data.site.siteMetadata.email}`}
              style={LinkStyling}
            >
              &nbsp;{data.site.siteMetadata.email}
            </a>
          </span>
        </p>
        <p>
          <Icon style={{ marginRight: "0.5rem" }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </Icon>{" "}
          <span><a href="https://goo.gl/maps/ytwgojMKwkTdGgQt8" style={LinkStyling} target="_blank" rel="noopener noreferrer">
            {data.site.siteMetadata.address}
          </a></span>
        </p>
      </div>
      <ModalImageContainer>
        <ModalImage
          small={lageplan_large}
          large={lageplan_large}
          alt="Lageplan"
          style={{ height: "10rem" }}
        />
      </ModalImageContainer>
    </ContactContainer>
    <div style={{backgroundColor: colors.LIGHTGREY, padding: "2rem 0"}}>
    <Center>
      <h2>Ordinationszeiten</h2>
    </Center>
    <Container>
      <div style={{ margin: "auto", display: "flex", flexDirection: "column" }}>
        <div style={{ margin: "auto" }}>
          <Table className="center-table">
            <tbody>
              <tr>
                <td>MO</td>
                <td>-</td>
                <td>14:00 - 19:00 Uhr</td>
              </tr>
              <tr>
                <td>DI</td>
                <td>09:00 - 14:00 Uhr</td>
                <td>-</td>
              </tr>
              <tr>
                <td>MI</td>
                <td>-</td>
                <td>13:00 - 18:00 Uhr</td>
              </tr>
              <tr>
                <td>DO</td>
                <td>09:00 - 14:00 Uhr</td>
                <td>-</td>
              </tr>
              <tr>
                <td>FR</td>
                <td colSpan="2">nach Vereinbarung</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <p style={{ textAlign: "center" }}>
          Um Terminvereinbarung unter der Nummer{" "}
          {data.site.siteMetadata.telephone} oder über das Online-Tool wird
          gebeten.
        </p>
      </div>
    </Container>
    </div>
  </MainLayout>
)

export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
        telephone
        fax
        email
        address
      }
    }
  }
`
